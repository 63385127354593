import { useContext } from "react";
import logo from "./logo.svg";
import Deposit from "./components/Deposit";
import Withdrawal from "./components/Withdrawal";
import Report from "./components/Report";
import { AppContext } from "./contexts/AppContext";
import Loader from "./components/Loader";
import Tab from "./components/Tab";

function App() {
  const { active, data, setData } = useContext(AppContext);

  return (
    <section
      className="bg-white bg-cover bg-no-repeat min-h-screen"
      style={{ backgroundImage: 'url("wallpaper.jpg")' }}
    >
      {data === undefined || data.length === 0 ?
        <div className="pt-96">
          <Loader />
        </div> : (
          <div className="max-w-7xl px-6 py-10 mx-auto">
            <img src={logo} alt="" className="w-20 h-20 mx-auto" />
            <h1 className="text-3xl font-bold lg:text-4xl text-white text-center text-gradient">
              Switch Wallet Tracker
            </h1>
            <Tab get />
            {active === "report" ? (
              <Report data={data} setData={setData} />
            ) : null || active === "deposit" ? (
              <Deposit />
            ) : null || active === "withdrawal" ? (
              <Withdrawal />
            ) : null}
          </div>
        )}
    </section>
  );
}

export default App;