import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import { AppContext } from "../contexts/AppContext";

function Report({ data }) {
  const {
    checkData,
    handleSubmit,
    setStartDate,
    setEndDate,
    loading,
    startDate,
    endDate,
  } = useContext(AppContext);

  return (
    <>
      <div className="max-w-3xl mx-auto text-center mb-5">
        <form className="flex flex-col mt-10 sm:flex-row sm:justify-center sm:-mx-2 gap-3">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Select start date"
            className="w-full p-3 text-black bg-white border rounded-md sm:mx-2  focus:border-none focus:outline-none focus:ring focus:ring-none focus:ring-opacity-0"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Select end date"
            className="w-full p-3 text-black bg-white border rounded-md sm:mx-2  focus:border-none focus:outline-none focus:ring focus:ring-none focus:ring-opacity-0"
          />
          <button
            onClick={handleSubmit}
            className="p-3 w-full flex justify-center gap-2 font-bold text-white capitalize transition-colors duration-300 transform rounded-full sm:mx-2 bg-gradient-to-r from-purple-600 to-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                clipRule="evenodd"
              />
            </svg>
            Filter
          </button>
        </form>
      </div>
      <div className="grid grid-cols-1 gap-8 xl:gap-10 md:grid-cols-2 xl:grid-cols-3">
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Common failure reason
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading ? "Loading..." : checkData(data.commonFailureReason)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Deposit average time
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading ? "Loading..." : checkData(data.depositAverageTime)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Deposits not within thirthy min
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading
                ? "Loading..."
                : checkData(data.depositsNotWithinThirthyMin)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Deposits within thirthy min
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading
                ? "Loading..."
                : checkData(data.depositsWithinThirthyMin)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">Total deposits</h1>
            <h5 className="font-semibold text-sm text-white">
              {loading ? "Loading..." : checkData(data.totalDeposits)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">Total withdrawals</h1>
            <h5 className="font-semibold text-sm text-white">
              {loading ? "Loading..." : checkData(data.totalWithdrawals)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Withdrawal average time
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading ? "Loading..." : checkData(data.withdrawalAverageTime)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Withdrawal within thirthy min
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading
                ? "Loading..."
                : checkData(data.withdrawalWithinThirthyMin)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden">
          <div className="p-8 space-y-3 rounded-2xl bg-gradient-to-r from-purple-600 to-indigo-600">
            <h1 className="font-bold text-lg text-white">
              Withdrawals not within thirthy min
            </h1>
            <h5 className="font-semibold text-sm text-white">
              {loading
                ? "Loading..."
                : checkData(data.withdrawalsNotWithinThirthyMin)}
            </h5>
            <div className="flex justify-end">
              <p className="font-semibold text-sm mt-4 text-white xl:mt-6 border-white border rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;