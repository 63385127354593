import { createContext, useEffect, useState } from "react";
import Report from "../components/Report";
import Deposit from "../components/Deposit";
import axios from "axios";
import moment from "moment";
import Withdrawal from "../components/Withdrawal";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("report");
  const [startDate, setStartDate] = useState(Date | null);
  const [endDate, setEndDate] = useState(Date | null);
  const [depositStartDate, setDepositStartDate] = useState(Date | null);
  const [depositEndDate, setDepositEndDate] = useState(Date | null);
  const [withdrawalStartDate, setWithdrawalStartDate] = useState(Date | null);
  const [withdrawalEndDate, setWithdrawalEndDate] = useState(Date | null);

  const [setOffset] = useState(0);
  const [perPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);

  let pageSize = 1;
  let page = 1;

  function getString(e) {
    let text = e.target.value;

    if (text === "report") {
      setActive("report");
      return <Report />;
    } else if (text === "deposit") {
      setActive("deposit");
      return <Deposit />;
    } else if (text === "withdrawal") {
      setActive("withdrawal");
      return <Withdrawal />;
    } else {
      setActive("report");
      return <Report />;
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT}/api/v1/issueTracker/getIssueReport`
      );
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const checkData = (text) => {
    if (text === null || text === undefined) {
      return "0";
    } else {
      return text;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT
        }/api/v1/issueTracker/getIssueReport?from=${moment(startDate).format(
          "L"
        )}&to=${moment(endDate).format("L")}`
      );
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchDeposit = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT}/api/v1/issueTracker/Deposits`
      );
      setDeposits(response.data.data.records);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDeposit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT
        }/api/v1/issueTracker/Deposits?From=${moment(depositStartDate).format(
          "L"
        )}&To=${moment(depositEndDate).format(
          "L"
        )}&Page=${page}&PageSize=${pageSize}`
      );
      setDeposits(response.data.data.records);
      setPageCount(Math.ceil(response.data.data.totalPages / perPage));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;

    setOffset((selectedPage + 1) * perPage);
  };

  const handlePageClose = (e) => {
    const selectedPage = e.selected;

    setOffset((selectedPage - 1) * perPage);
  };

  const fetchWithdrawal = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT}/api/v1/issueTracker/FailedWithdrawals`
      );
      setWithdrawals(response.data.data.records);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleWithdrawal = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL_DEVELOPMENT
        }/api/v1/issueTracker/FailedWithdrawals?From=${moment(
          withdrawalStartDate
        ).format("L")}&To=${moment(withdrawalEndDate).format(
          "L"
        )}&Page=${page}&PageSize=${pageSize}`
      );
      setWithdrawals(response.data.data.records);
      setPageCount(Math.ceil(response.data.data.totalPages / perPage));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const shortenAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;

  useEffect(() => {
    fetchData();
    fetchWithdrawal();
    fetchDeposit();
  }, []);

  return (
    <AppContext.Provider
      value={{
        handleSubmit,
        handleWithdrawal,
        checkData,
        fetchData,
        getString,
        setActive,
        setData,
        setLoading,
        setStartDate,
        setEndDate,
        handleDeposit,
        shortenAddress,
        setDepositStartDate,
        setDepositEndDate,
        setWithdrawalStartDate,
        setWithdrawalEndDate,
        handlePageClick,
        handlePageClose,
        pageCount,
        deposits,
        startDate,
        endDate,
        depositStartDate,
        depositEndDate,
        withdrawalStartDate,
        withdrawalEndDate,
        loading,
        active,
        data,
        withdrawals,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
