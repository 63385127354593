import React from 'react';
import { useContext } from "react";
import { AppContext } from '../contexts/AppContext';

function Tab() {
    const { getString } = useContext(AppContext);
    return (
        <div className="flex mt-10 items-center justify-center">
            <div className="grid w-[40rem] grid-cols-3 space-x-3 rounded-full bg-gray-200 p-2" x-data="app">
                <div>
                    <input type="radio" name="option" id="1" className="peer hidden" defaultChecked value="report" onClick={getString} />
                    <label htmlFor="1" className="block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">Report</label>
                </div>
                <div>
                    <input type="radio" name="option" id="2" className="peer hidden" value="deposit" onClick={getString} />
                    <label htmlFor="2" className="block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">Deposit</label>
                </div>
                <div>
                    <input type="radio" name="option" id="3" className="peer hidden" value="withdrawal" onClick={getString} />
                    <label htmlFor="3" className="block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">Withdrawal</label>
                </div>
            </div>
        </div>
    )
}

export default Tab;