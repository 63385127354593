import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import { AppContext } from "../contexts/AppContext";
import Loader from "./Loader";

const Withdrawal = () => {
  const {
    withdrawalStartDate,
    withdrawalEndDate,
    setWithdrawalStartDate,
    setWithdrawalEndDate,
    handlePageClick,
    pageCount,
    withdrawals,
    handleWithdrawal,
    shortenAddress,
    loading
  } = useContext(AppContext);

  return (
    <div>
      <div className="max-w-3xl mx-auto text-center mb-5">
        <form className="flex flex-col mt-10 sm:flex-row sm:justify-center sm:-mx-2 gap-3">
          <DatePicker
            selected={withdrawalStartDate}
            onChange={(date) => setWithdrawalStartDate(date)}
            placeholderText="Select start date"
            className="w-full p-3 text-black bg-white border rounded-md sm:mx-2  focus:border-none focus:outline-none focus:ring focus:ring-none focus:ring-opacity-0"
          />
          <DatePicker
            selected={withdrawalEndDate}
            onChange={(date) => setWithdrawalEndDate(date)}
            placeholderText="Select end date"
            className="w-full p-3 text-black bg-white border rounded-md sm:mx-2  focus:border-none focus:outline-none focus:ring focus:ring-none focus:ring-opacity-0"
          />
          <button
            onClick={handleWithdrawal}
            className="p-3 w-full flex justify-center gap-2 font-bold text-white capitalize transition-colors duration-300 transform rounded-full sm:mx-2 bg-gradient-to-r from-purple-600 to-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                clipRule="evenodd"
              />
            </svg>
            Filter
          </button>
        </form>
      </div>

      {loading ? <div className="pt-40"><Loader /></div> :
        (
          withdrawals.length === 0 || withdrawals === [] ? (
            <div className="text-white font-bold flex  justify-center text-center">
              <p className="text-2xl font-medium font-serif">They is no data at the moment... Filter again!</p>
            </div>
          ) : withdrawals.map((withdrawal, index) => (
            <div key={index} className="w-full overflow-hidden mt-10">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left uppercase border-b bg-white text-black">
                      <th className="px-4 py-3">Amount</th>
                      <th className="px-4 py-3">Symbol</th>
                      <th className="px-4 py-3">Address</th>
                      <th className="px-4 py-3">Failure</th>
                      <th className="px-4 py-3">Reference</th>
                      <th className="px-4 py-3">Network</th>
                      <th className="px-4 py-3">Status</th>
                      <th className="px-4 py-3">Charge</th>
                      <th className="px-4 py-3">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-black bg-white">
                      <td className="px-4 py-3">
                        <div className="flex items-center text-sm">
                          <div>
                            <p className="font-semibold">{withdrawal.amount}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm">{withdrawal.currencySymbol}</td>
                      <td className="px-4 py-3 text-xs"><span className="px-2 py-1 font-semibold">{shortenAddress(withdrawal.destinationAddress)}</span></td>
                      <td className="px-4 py-3 text-sm">{withdrawal.failureReason}</td>
                      <td className="px-4 py-3 text-sm">{shortenAddress(withdrawal.merchantReference)}</td>
                      <td className="px-4 py-3 text-sm">{withdrawal.networkChain}</td>
                      <td className="px-4 py-3 text-sm">{withdrawal.status}</td>
                      <td className="px-4 py-3 text-sm">{withdrawal.transactionCharge}</td>
                      <td className="px-4 py-3 text-sm">{withdrawal.transactionDate}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex justify-center">
                  <ReactPaginate
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="Prev"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          ))
        )}
    </div>
  );
};

export default Withdrawal;